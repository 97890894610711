<template>
  <div>
    <BlockUI :blocked="blocked" :fullScreen="true">
      <div class="topbar">
        <div class="topbar-start">
          <router-link :to="{ name: 'ProductProducts' }" class="back">
            <i class="pi pi-arrow-left" style="font-size: 0.8rem"></i> Products
          </router-link>
        </div>

        <div class="topbar-end">
          <Button class="saveProduct" @click="saveProduct">Save</Button>
        </div>
      </div>

      <div class="main-content">
        <div class="left-content">
          <div>
            <Card>
              <template #content>
                <div class="product-image-wrap">
                  <img
                    src="@/assets/image-placeholder.png"
                    @click="fileInput.click()"
                    style="cursor: pointer"
                    ref="filePreview"
                  />
                  <input
                    style="display: none"
                    type="file"
                    @change="onFileSelected"
                    ref="fileInput"
                  />
                </div>
              </template>
            </Card>
          </div>
          <div style="margin-top: 0.5rem">
            Brand
            <span
              style="
                margin-left: 0.5rem;
                cursor: pointer;
                color: blue;
                text-decoration: underline;
              "
              @click="showNewBrandDialog"
            >
              New
            </span>
          </div>
          <div>
            <KBaseDropdown
              v-model="productState.brand_id"
              :options="optionBrands"
            />
          </div>

          <div style="margin-top: 0.5rem">Status</div>
          <div>
            <KBaseDropdown
              v-model="productState.status_id"
              :options="optionStatuses"
            />
          </div>

          <div style="margin-top: 0.5rem">Priority</div>
          <div>
            <KBaseDropdown
              v-model="productState.priority_id"
              :options="optionPriorities"
            />
          </div>

          <div style="margin-top: 0.5rem">Note</div>
          <div>
            <Textarea
              v-model="productState.note"
              rows="5"
              :autoResize="true"
              style="width: 100%"
            />
          </div>
        </div>

        <div class="right-content">
          <Card>
            <template #content>
              <h3>Product Detail</h3>

              <div style="font-weight: 500">Name</div>
              <div>
                <InputText
                  type="text"
                  v-model="productState.name"
                  style="width: 100%"
                />
              </div>

              <div style="font-weight: 500; margin-top: 0.5rem">
                Product Analysis
              </div>
              <div>
                <Editor
                  v-model="productState.analysis"
                  editorStyle="height: 320px"
                >
                  <template v-slot:toolbar>
                    <span class="ql-formats">
                      <button class="ql-bold" v-tooltip.bottom="'Bold'" />
                      <button class="ql-italic" v-tooltip.bottom="'Italic'" />
                      <button
                        class="ql-underline"
                        v-tooltip.bottom="'Underline'"
                      />
                    </span>
                  </template>
                </Editor>
              </div>
            </template>
          </Card>

          <Card style="margin-top: 1rem">
            <template #content>
              <div style="font-weight: 500">ASIN</div>
              <div>
                <InputText
                  type="text"
                  v-model="productState.asin"
                  style="width: 100%"
                />
              </div>
            </template>
          </Card>
        </div>
      </div>

      <Dialog header="New Brand" v-model:visible="displayNewBrand">
        <div>
          Name
          <InputText type="text" v-model="brandName" style="width: 100%" />
        </div>
        <div style="margin-top: 0.5rem">
          Note
          <InputText type="text" v-model="brandNote" style="width: 100%" />
        </div>

        <template #footer>
          <Button
            label="Cancel"
            icon="pi pi-times"
            @click="closeNewBrandDialog"
            class="p-button-text"
          />
          <Button label="OK" icon="pi pi-check" @click="addBrand" autofocus />
        </template>
      </Dialog>
    </BlockUI>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { useToast } from "primevue/usetoast";

import BlockUI from "primevue/blockui";
import Button from "primevue/button";
import Card from "primevue/card";
import Dialog from "primevue/dialog";
import Editor from "primevue/editor";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";

import KBaseDropdown from "@/components//KBaseDropDown";

import BrandService from "@/services/brand_service";
import ProductService from "@/services/product_service";

export default defineComponent({
  components: {
    BlockUI,
    Button,
    Card,
    Dialog,
    Editor,
    InputText,
    Textarea,

    KBaseDropdown,
  },

  setup() {
    onMounted(() => {
      BrandService.list().then(({ data }) => {
        optionBrands.value = data.brands.map((brand) => ({
          label: brand.name,
          value: brand.id,
        }));
        productState.brand_id = optionBrands.value[0].value;
      });

      ProductService.listStatuses().then(({ data }) => {
        optionStatuses.value = data.statuses.map((status) => ({
          label: status.name,
          value: status.id,
        }));
        productState.status_id = optionStatuses.value[0].value;
      });

      ProductService.listPriorities().then(({ data }) => {
        optionPriorities.value = data.priorities.map((priority) => ({
          label: priority.name,
          value: priority.id,
        }));
        productState.priority_id = optionPriorities.value[0].value;
      });
    });

    const router = useRouter();

    const blocked = ref(false);
    const toast = useToast();

    const optionBrands = ref([]);
    const optionStatuses = ref([]);
    const optionPriorities = ref([]);

    const productState = reactive({
      name: "",
      note: "",
      analysis: "",
      imageFile: "",
      brand_id: "",
      status_id: "",
      priority_id: "",
      asin: null,
    });
    const filePreview = ref();
    const fileInput = ref();

    const displayNewBrand = ref(false);
    const brandName = ref("");
    const brandNote = ref("");

    const onFileSelected = (event) => {
      productState.imageFile = event.target.files[0];
      filePreview.value.src = URL.createObjectURL(productState.imageFile);
    };

    const saveProduct = () => {
      const formData = new FormData();
      formData.append("product[name]", productState.name);
      formData.append("product[note]", productState.note);
      formData.append("product[analysis]", productState.analysis);
      formData.append("product[brand_id]", productState.brand_id);
      formData.append("product[status_id]", productState.status_id);
      formData.append("product[priority_id]", productState.priority_id);
      formData.append("product[asin]", productState.asin);
      if (productState.imageFile) {
        formData.append(
          "product[image]",
          productState.imageFile,
          productState.imageFile.name
        );
      }

      blocked.value = true;

      ProductService.createProduct(formData)
        .then(() => {
          console.log("created!");
          toast.add({
            severity: "success",
            summary: "Product created!",
            life: 3000,
          });

          router.push({ name: "ProductProducts" });
        })
        .catch((e) => {
          console.error(e);
          toast.add({
            severity: "error",
            summary: "Product not created!",
            life: 3000,
          });
        })
        .finally(() => {
          blocked.value = false;
        });
    };

    const showNewBrandDialog = () => {
      brandName.value = "";
      brandNote.value = "";
      displayNewBrand.value = true;
    };

    const closeNewBrandDialog = () => {
      displayNewBrand.value = false;
    };

    const addBrand = () => {
      const name = brandName.value.trim();
      const note = brandNote.value.trim();

      if (name === "") {
        toast.add({
          severity: "error",
          summary: "Brand Name is required",
          life: 3000,
        });
        return;
      }

      const payload = {
        brand: {
          name: name,
          note: note,
        },
      };

      BrandService.create(payload)
        .then((resp) => {
          toast.add({
            severity: "success",
            summary: "Brand created",
            life: 3000,
          });

          BrandService.list().then(({ data }) => {
            optionBrands.value = data.brands.map((brand) => ({
              label: brand.name,
              value: brand.id,
            }));
            productState.brand_id = resp.data.id;
            displayNewBrand.value = false;
          });
        })
        .catch((e) => {
          console.error(e);
          toast.add({
            severity: "error",
            summary: "Brand not created",
            life: 3000,
          });
        });
    };

    return {
      blocked,

      optionBrands,
      optionStatuses,
      optionPriorities,

      productState,
      filePreview,
      fileInput,

      onFileSelected,
      saveProduct,

      showNewBrandDialog,
      displayNewBrand,
      brandName,
      brandNote,
      closeNewBrandDialog,
      addBrand,
    };
  },
});
</script>

<style lang="scss" scoped>
.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .topbar-start {
    display: flex;
    align-items: flex-start;
  }

  .topbar-end {
    display: flex;
    align-items: flex-end;
  }
}

.back {
  color: #3653f1;
  font-weight: 500;
}

.saveProduct {
  background: #3653f1;
  border: #3653f1;

  &:hover {
    background: #536aec;
    border: #536aec;
  }
}

.main-content {
  display: flex;
  vertical-align: top;
  width: 100%;

  margin-top: 1rem;

  .left-content {
    flex: 0 0 200px;

    padding-right: 1rem;
    font-weight: 500;
  }

  .right-content {
    flex: 1 1 800px;
  }
}

.product-image-wrap {
  img {
    width: 180px;
  }
}

::v-deep .p-avatar-xl {
  width: 100%;
  height: 100%;
}

::v-deep .p-dropdown {
  width: 100%;
}
</style>
